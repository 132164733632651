import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import { useIdmeAuth } from '../../hooks/user';

export default function Callback() {
  const [codeError, setCodeError] = useState(null);
  let location = useLocation();
  const { mutate: oauthLogin, error } = useIdmeAuth();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');

    if (code) {
      oauthLogin(code);
    } else {
      setCodeError('not-found');
    }
  }, [location, oauthLogin]);

  if (error || codeError === 'not-found') {
    const message = error && error?.message ? `&message=${error.message}` : '';
    return <Redirect to={`/login?error=true${message}`} />;
  }
  return <Spinner />;
}
