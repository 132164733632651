import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from './user';
import Urls from '../app/urls';
import history from '../app/history';

export default function useRouteUser() {
  const location = useLocation();
  const { data: user } = useUser();

  const missingBasicProfile = !user?.profile?.has_completed_profile;

  const applyStatus = !user?.profile?.email_confirmed;

  useEffect(() => {
    if (!user) return null;
    if (missingBasicProfile) {
      return history.push(Urls.basicProfile);
    } else if (applyStatus) {
      return history.push(Urls.basicProfileComplete);
    }
  }, [user, missingBasicProfile, applyStatus, location.pathname]);

  return user;
}
