import React from 'react';
import {
  Button,
  Flex,
  Link,
  Spacer,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import CardContainer from '../layout/CardContainer';
import { useUser } from '../../hooks/user';

export default function ProfileComplete() {
  const { data: user } = useUser();

  const { hasCopied, onCopy } = useClipboard(
    `https://${window.location.hostname}/${user.profile.sharing_url_segment}/verify`
  );

  const heading = !user?.profile?.email_confirmed
    ? 'Please Confirm Your Email'
    : 'Transition Overwatch Bonus Program';

  return (
    <CardContainer heading={heading}>
      {!user?.profile?.email_confirmed && (
        <>
          <Text pb={2} pl={2}>
            Thanks {user.first_name} for submitting your registration. The next
            step is to confirm your email address. We just emailed you at{' '}
            {user.email}. Please check your inbox and confirm your email
            address.
          </Text>
          <Text pb={2} pl={2}>
            Thanks!
          </Text>
        </>
      )}

      {user?.profile?.email_confirmed && (
        <>
          <Text pb={2} pl={2}>
            Help us spread the word about Transition Overwatch and get rewarded!
          </Text>

          <Text pb={2} pl={2}>
            <strong>STEP 1</strong>: Copy your unique Transition Overwatch
            registration link using the button below
          </Text>

          <Text pb={2} pl={2}>
            <strong>STEP 2</strong>: Share your unique registration link with
            your friends and teammates and encourage them to register
          </Text>

          <Text pb={2} pl={2}>
            <strong>STEP 3</strong>: Earn a $300 referral bonus for each
            military member you refer who accepts an offer of employment with a
            Transition Overwatch Employer Partner.
          </Text>

          <Flex pb={2}>
            <Spacer />
            <Button onClick={onCopy} ml={2}>
              {hasCopied ? 'Copied!' : 'Copy'}
            </Button>
            <Spacer />
          </Flex>

          <Flex pb={2}>
            <Spacer />
            <Link
              href="https://transitionoverwatch.com/terms-of-use/#bonus"
              color="brand.500"
              target="_blank"
            >
              Bonus Program Details
            </Link>
            <Spacer />
          </Flex>
        </>
      )}
    </CardContainer>
  );
}
