import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Checkbox,
  Divider,
  Fade,
  Flex,
  Grid,
  Heading,
  Icon,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import {
  FaSignInAlt,
  FaCertificate,
  FaUserCheck,
  FaSearch,
} from 'react-icons/fa';
import { useLocation, useParams, Link as RouterLink } from 'react-router-dom';
import { useQueryClient, useQuery } from 'react-query';
import Spinner from '../layout/Spinner';
import { useConfig } from '../../hooks/user';
import { getCareerPublic } from '../../hooks/careers';
import IdmeLogo from './idme-rectangle-outlined-verify.svg';
import Urls from '../../app/urls';

export default function Login() {
  let location = useLocation();
  const { source } = useParams();
  const [error, setError] = useState(null);
  const [animation, setAnimation] = useState(null);
  const [terms, setTerms] = useState(false);
  const [urlSource, setUrlSource] = useState(null);
  const queryClient = useQueryClient();
  const { data: config, isLoading } = useConfig();
  const { data: careerPublic } = useQuery(
    'careerPublic',
    () => getCareerPublic(source),
    {
      enabled: !!urlSource,
    }
  );

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://s3.amazonaws.com/idme/developer/idme-buttons/assets/js/idme-wallet-button.js';
    script.async = true;
    document.body.appendChild(script);
    const termsAndConds = localStorage.getItem('t&c');
    if (termsAndConds) setTerms(true);
  }, []);

  useEffect(() => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    queryClient.invalidateQueries('users');
  }, [queryClient]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const error = searchParams.get('error');
    const message = searchParams.get('message');

    if (source) {
      setUrlSource(source);
      localStorage.setItem('source', source);
    }

    if (error === 'user-exists') {
      setError('User exists.  Please login.');
    } else if (error) {
      setError(message ? message : 'Error. Please try again.');
    } else {
      setError(null);
    }
  }, [location, source]);

  if (!config || isLoading) return <Spinner />;

  const handleTermsClick = (evt) => {
    setTerms(!terms);
    if (evt.target.checked) {
      console.log('adding');
      localStorage.setItem('t&c', true);
    } else {
      localStorage.removeItem('t&c');
    }
  };

  return (
    <Flex direction="column" align="center" m="0 auto">
      <Grid h="100vh" w="100%" bg="gray.300">
        <Center h="100%" w="100%">
          <Box
            maxW="md"
            ml="1"
            mr="1"
            borderWidth="1px"
            bg="white"
            boxShadow="md"
          >
            <Box minH={source && careerPublic?.name ? '200px' : '150px'}>
              <Fade in={true}>
                <Image
                  p={6}
                  fallback={<div />}
                  src={process.env.PUBLIC_URL + '/images/to-logo.png'}
                  alt="Transition Overwatch Logo"
                />
              </Fade>

              {source && careerPublic && careerPublic.name && (
                <Center pb={6}>
                  <Fade in={true}>
                    <Heading align="center">{careerPublic.name}</Heading>
                    <Heading align="center" size="sm" pt="15px">
                      {careerPublic.companyName}{' '}
                      <em>powered by Transition Overwatch</em>
                    </Heading>
                  </Fade>
                </Center>
              )}
            </Box>

            <Divider />

            <Box p="6">
              <Box p="6" pt="3" pb="1">
                <Text fontSize="xl">
                  <Icon as={FaCertificate} mr={1} color="brand.primary" />
                  <strong>Step 1 </strong>
                  Get verified with ID.me
                </Text>
              </Box>
              <Box p="6" pb="1">
                <Text fontSize="xl">
                  <Icon as={FaUserCheck} mr={1} color="brand.primary" />
                  <strong>Step 2 </strong> Complete your Profile
                </Text>
              </Box>
              <Box p="6" pb="5">
                <Text fontSize="xl">
                  <Icon as={FaSearch} mr={1} color="brand.primary" />
                  <strong>Step 3 </strong> Profile Review
                </Text>
              </Box>

              {/* {cutOffDate && (
                <Box p="6" pt="1" pb="5">
                  <Text fontSize="xl">
                    <Icon
                      as={FaExclamationCircle}
                      mr={1}
                      mt={1}
                      color="orange"
                    />
                    Now accepting military members who transition on or after{' '}
                    <strong>{cutOffDate}</strong>.
                  </Text>
                </Box>
              )} */}

              <Divider />

              <Box pt={3}>
                <Heading as="h5" size="md">
                  <Center w="100%">
                    <Icon as={FaSignInAlt} mr={1} color="brand.primary" />{' '}
                    Register
                  </Center>
                </Heading>
              </Box>

              <Box>
                <motion.div
                  animate={animation || { scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Checkbox p={1} onChange={handleTermsClick} isChecked={terms}>
                    <Text>
                      I've read and accepted the{' '}
                      <Link
                        href="https://transitionoverwatch.com/terms-of-use"
                        isExternal
                      >
                        <Text as="u">terms of use</Text>
                      </Link>{' '}
                      and{' '}
                      <Link
                        href="https://transitionoverwatch.com/privacy-policy"
                        isExternal
                      >
                        <Text as="u">privacy policy.</Text>
                      </Link>
                    </Text>
                  </Checkbox>
                </motion.div>
              </Box>
              <Box d="flex" alignItems="baseline" p={3} pb={0}>
                <Center w="100%">
                  <Link
                    disabled={!config || !config.idmeClientId}
                    onClick={
                      !terms
                        ? () =>
                            setAnimation({
                              scale: [1, 1.1, 1.1, 1],
                              rotate: [0, 0.4, -0.4, 0.4, -0.4, 0],
                            })
                        : null
                    }
                    href={
                      terms
                        ? `https://api.id.me/oauth/authorize?client_id=${config?.idmeClientId}&redirect_uri=${config?.idmeRedirectUri}&response_type=code&scope=military`
                        : null
                    }
                  >
                    <Image
                      maxH="60px"
                      fallback={<div />}
                      width="100%"
                      minW="293px"
                      src={IdmeLogo}
                      alt="ID.me logo"
                    />
                  </Link>
                </Center>
              </Box>

              <Box d="flex" alignItems="baseline" p={3} pb={0}>
                <RouterLink to={Urls.login}>
                  Already have an account? <Text as="u">Login here.</Text>
                </RouterLink>
              </Box>

              {error && (
                <Box pt="3" d="flex" alignItems="baseline">
                  <Alert status="error">
                    <AlertIcon />
                    {error}
                  </Alert>
                </Box>
              )}
            </Box>
          </Box>
        </Center>
      </Grid>
    </Flex>
  );
}
