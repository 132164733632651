import React from 'react';
import { Switch, Router, Route, Redirect } from 'react-router-dom';

import Urls from './app/urls';
import history from './app/history';

import ErrorBoundary from './app/ErrorBoundary';

import InternalLayout from './features/layout/Internal';

import IdMeCallback from './features/idme/callback';
import IdMeLoginCallback from './features/idme/callback-login';

import EmailConfirmation from './features/email/Confirmation';
import Dashboard from './features/dashboard/Page';
import Login from './features/user/Login';
import Verify from './features/user/Verify';
import PrivateRoute from './app/PrivateRoute';
import BasicProfile from './features/user/BasicProfile';
import BasicProfileComplete from './features/user/BasicProfileComplete';

const redirectToDash = <Redirect to={Urls.dashboard} />;

const internalRoutes = [
  {
    url: Urls.basicProfileComplete,
    component: BasicProfileComplete,
    exact: true,
  },
  { url: Urls.basicProfile, component: BasicProfile, exact: true },
  { url: Urls.dashboard, component: Dashboard, exact: false },
];

const internalUrlArr = internalRoutes.map((r) => r.url);

const InternalApp = () => (
  <InternalLayout>
    <Switch>
      {internalRoutes.map((r, k) => (
        <Route
          exact={r.exact}
          path={r.url}
          component={r.component}
          key={r.url}
        />
      ))}
    </Switch>
  </InternalLayout>
);

export default function App() {
  return (
    <ErrorBoundary>
      <Router history={history}>
        <Switch>
          {/* Public Routes */}
          <Route exact path={Urls.idMeCallback} component={IdMeCallback} />
          <Route exact path="/" render={() => redirectToDash} />
          <Route
            exact
            path={Urls.idMeLoginCallback}
            component={IdMeLoginCallback}
          />

          <Route exact path={Urls.partnerVerify} component={Verify} />
          <Route exact path={Urls.login} component={Login} />
          <Route exact path={Urls.verify} component={Verify} />

          <Route
            exact
            path={Urls.emailConfirmation}
            component={EmailConfirmation}
          />

          {/* Private Routes */}
          <PrivateRoute path={internalUrlArr} component={InternalApp} />
        </Switch>
      </Router>
    </ErrorBoundary>
  );
}
