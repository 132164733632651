import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Divider,
  Fade,
  Flex,
  Grid,
  Heading,
  Icon,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import { useIntercom } from 'react-use-intercom';
import { FaSignInAlt } from 'react-icons/fa';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import Spinner from '../layout/Spinner';
import { useConfig } from '../../hooks/user';
import IdmeLogo from './idme-rectangle-outlined.svg';
import Urls from '../../app/urls';

export default function Login() {
  let location = useLocation();
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();
  const { data: config, isLoading } = useConfig();
  const { hardShutdown } = useIntercom();

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://s3.amazonaws.com/idme/developer/idme-buttons/assets/js/idme-wallet-button.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => hardShutdown(), [hardShutdown]);

  useEffect(() => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    queryClient.invalidateQueries('users');
  }, [queryClient]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const error = searchParams.get('error');
    const message = searchParams.get('message');

    if (error === 'user-exists') {
      setError('User exists.  Please login.');
    } else if (error) {
      setError(message ? message : 'Error. Please try again.');
    } else {
      setError(null);
    }
  }, [location]);

  if (!config || isLoading) return <Spinner />;

  return (
    <Flex direction="column" align="center" m="0 auto">
      <Grid h="100vh" w="100%" bg="gray.300">
        <Center h="100%" w="100%">
          <Box
            maxW="md"
            ml="1"
            mr="1"
            borderWidth="1px"
            bg="white"
            boxShadow="md"
          >
            <Box minH="150px">
              <Fade in={true}>
                <Image
                  p={6}
                  pb={3}
                  fallback={<div />}
                  src={process.env.PUBLIC_URL + '/images/to-logo.png'}
                  alt="Transition Overwatch Logo"
                />
              </Fade>
            </Box>

            <Divider />

            <Box p="6">
              <Box pt={3}>
                <Heading as="h5" size="md">
                  <Center w="100%">
                    <Icon as={FaSignInAlt} mr={1} color="brand.primary" /> Login
                  </Center>
                </Heading>
              </Box>

              <Box d="flex" alignItems="baseline" p={3} pb={0}>
                <Center w="100%">
                  <Link
                    disabled={!config || !config.idmeClientId}
                    href={`https://api.id.me/oauth/authorize?client_id=${config?.idmeClientId}&redirect_uri=${config?.idmeRedirectUri}-auth&response_type=code&scope=military`}
                  >
                    <Image
                      maxH="60px"
                      fallback={<div />}
                      width="100%"
                      minW="293px"
                      src={IdmeLogo}
                      alt="ID.me logo"
                    />
                  </Link>
                </Center>
              </Box>

              <Box d="flex" alignItems="baseline" p={3} pb={0}>
                <RouterLink to={Urls.verify}>
                  New to Transition Overwatch? <Text as="u">Register Here</Text>
                </RouterLink>
              </Box>

              {error && (
                <Box pt="3" d="flex" alignItems="baseline">
                  <Alert status="error">
                    <AlertIcon />
                    {error}
                  </Alert>
                </Box>
              )}
            </Box>
          </Box>
        </Center>
      </Grid>
    </Flex>
  );
}
